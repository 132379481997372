<template>
  <div class="table">
    <div
      v-if="searchShow"
      class="searchBox"
      :style="{ 'flex-direction': flexDirection }">
      <div class="searchLeft">
        <div v-if="leftShow" class="searchItemBox">
          <slot name="searchLeftButtonList" :entitys="entitys" />
          <div
            class="searchTop"
            :class="{
              borderCumtom: entitys.every((item) => item.show === true),
            }">
            <div
              v-for="(entity, index) in entitys"
              :key="index"
              class="searchItem">
              <el-collapse-transition>
                <transition name="el-zoom-in-top">
                  <div
                    v-show="index === 0 ? true : entity.show"
                    :ref="`searchContent${index}`"
                    class="searchContent">
                    <el-select
                      v-model="entity.querySelect"
                      placeholder="请选择搜索项"
                      :popper-append-to-body="false"
                      :size="searchButtonSize"
                      value-key="prop"
                      clearable>
                      <el-option
                        v-for="item in columns.filter(
                          (item) => item.query !== false
                        )"
                        :key="item.prop"
                        :label="item.label"
                        :value="item" />
                    </el-select>
                    <slot name="searchLeft" :entitys="entitys" :index="index" />
                  </div>
                </transition>
              </el-collapse-transition>
              <el-button
                class="BlueCustomButton searchBottom"
                :size="searchButtonSize"
                @click.native="searchList(true)">查询</el-button>
              <el-button
                class="BlueCustomButton searchBottom"
                :size="searchButtonSize"
                @click.native="elTableRefs">重置</el-button>
              <slot name="export" :entitys="entitys" />
            </div>
          </div>
        </div>
        <slot name="searchJournal" />
      </div>
      <div class="searchRight">
        <slot name="searchRight" />
      </div>
    </div>
    <slot name="searchBottom" />
    <slot name="elTabs" />
    <el-table
      :span-method="spanMethod"
      :key="timer"
      ref="elTableRefs"
      v-if="refreshTable"
      v-loading="loadingData"
      :data="tableData"
      :class="{ tableBox: true, minHeigth500: tableData.length > 0 }"
      :stripe="stripe"
      v-bind="$attrs"
      row-key="id"
      :default-expand-all="expandFl"
      :show-header="showHeader"
      :load="load"
      :expand-row-keys="expandRowKeys"
      :highlight-current-row="radio"
      :max-height="maxHeight"
      :height="tableHeight"
      :row-class-name="rowClassName"
      @selection-change="handleSelectionChange"
      @select-all="selectAll"
      @expand-change="expandChange"
      @select="selectChange"
      @row-click="rowClick"
      @current-change="radioHandleCurrentChange"
      @sort-change="sortChange"
      :default-sort="defaultSort=='4'?{prop: 'ratio', order: sortDing}:{prop: '', order: sortDing}">
      <el-table-column
        v-if="selection"
        :fixed="isFixed"
        type="selection"
        reserve-selection
        width="50"
        align="center" />
      <el-table-column
        v-if="tableType == 'index' && indexShow && columns.length > 0"
        label="序号"
        :type="tableType"
        :width="indexWidth || '80'"
        align="center"
        :index="(index) => index + (page - 1) * rows + 1" />
      <el-table-column v-if="radio" :width="50" label="选择">
        <template slot-scope="scope">
          <el-radio
            v-model="radioRow"
            :label="scope.row"
            name="radio"
            style="position: relative; left: 8PX">{{ "" }}</el-radio>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="tableType == 'expand' && indexShow && columns.length > 0"
        label="序号"
        :type="tableType"
        width="50"
        align="center">
        <template slot-scope="{ row, $index }">
          <el-form label-position="left" inline class="demo-table-expand">
            <slot name="expand" :props="row" :index="$index" />
          </el-form>
        </template>
      </el-table-column>
      <template v-for="column in columns.filter(
         (item) => item.form !== false
          )">
        <el-table-column
          v-if="!entitysShow.includes(column.prop)"
          :key="column.prop"
          :align="column.align || 'center'"
          :prop="column.prop"
          :label="column.label"
          :sortable="column.sortable"
          :sort-orders="['descending','ascending']"
          :min-width="column.width"
          :show-overflow-tooltip="showOverflowTooltip">
          <template slot-scope="{ row, $index }">
            <slot
              name="columnEspecial"
              :column="column"
              :row="row"
              :index="$index" />
          </template>
        </el-table-column>
      </template>
      <slot name="columnRight" />
    </el-table>
    <div
      v-if="paginationShow"
      :class="{ hidden: hidden }"
      class="pagination-container">
      <el-pagination
        :popper-class="popperClass"
        :background="background"
        :current-page.sync="currentPage"
        :page-size.sync="pageSize"
        :layout="layout"
        :page-sizes="pageSizes"
        :total="total"
        :small="small"
        :pager-count="pagerCount"
        v-bind="$attrs"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pagerCount:{
      type:Number,
      default:5
    },
    spanMethod:{
      type: Function,
      default:()=>{}
    },
    rowClassName:{
      type:Function,
      default:()=>{}
    },
    popperClass:{
      //控制分页样式
      type: String,
      default: '',
    },
    sortDing: {
      type: String,
      default: "descending",
    },
    refreshTable: {
      //强制刷新table
      type: Boolean,
      default: true,
    },
    defaultSort: {
      type: String,
      default: "1",
    },
    indexWidth: {
      default: 0,
    },
    maxHeight: {
      default: "100%",
    },
    tableHeight:{
      default: "94%",
    },
    radio: {
      type: Boolean,
      default: false,
    },
    emitParentFun: {
      type: Boolean,
      default: false,
    },
    entitysShow: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandRowKeys: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandFl: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    expandAll: {
      type: Boolean,
      default: false,
    },
    tableType: {
      type: String,
      default: "index",
    },
    flag: {
      // 表格前面的勾选框，false时为单选
      type: Boolean,
      default: true,
    },
    isFixed: {
      default: false,
    },
    leftShow: {
      type: Boolean,
      default: true,
    },
    paginationShow: {
      type: Boolean,
      default: true,
    },
    tableData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    columns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    widthEspecial: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // 搜索框左右排列
    flexDirection: {
      type: String,
      default: function () {
        return "row";
      },
    },
    // 搜索的size
    searchButtonSize: {
      type: String,
      default: function () {
        return "";
      },
    },
    // 搜索的显示
    searchShow: {
      type: Boolean,
      default: true,
    },
    // 序号的显示
    indexShow: {
      type: Boolean,
      default: true,
    },
    // 斑马线
    stripe: {
      type: Boolean,
      default: true,
    },
    // 表头的显示
    showHeader: {
      type: Boolean,
      default: true,
    },
    // 多选显示
    selection: {
      type: Boolean,
      default: false,
    },
    multipleSelection: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    /**
     *当内容过长被隐藏时显示 tooltip
     */
    showOverflowTooltip: {
      type: Boolean,
      default: function () {
        return true;
      },
    },

    // 下面是pagination
    total: {
      type: Number,
      default() {
        return 0;
      },
    },
    page: {
      type: Number,
      default: 1,
    },
    rows: {
      type: Number,
      default: 20,
    },
    pageSizes: {
      type: Array,
      default() {
        return [20, 30, 50];
      },
    },
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper",
    },
    background: {
      type: Boolean,
      default: true,
    },
    // 分页的显示
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timer: null,
      entitys: [
        {
          querySelect: {},
          queryValue: "",
          show: false,
        },
      ],
      loadingData: this.loading,
      time1: 0,
      time2: 0,
      radioRow: "",
    };
  },
  computed: {
    // 下面是pagination
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      },
    },
    pageSize: {
      get() {
        return this.rows;
      },
      set(val) {
        this.$emit("update:rows", val);
      },
    },
  },
  watch: {
    tableData: {
      handler() {
        this.$nextTick(() => {
          this.loadingData = false;
        });
      },
      deep: true,
    },
    columns: {
      handler() {
        this.reload()
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.$nextTick(() => {
      if (this.$refs["searchContent0"]) {
        const res_se0 = this.$refs["searchContent0"][0].querySelectorAll(
          "input"
        )[1];
        if (res_se0) {
          res_se0.addEventListener(
            "focus",
            () => {
              this.entitys.map((item) => {
                item.show = true;
              });
              if (this.entitys.length === 1) {
                this.entitys[0].show = false;
              }
            },
            { passive: false }
          );
        }
      }
    });
    this.watchMethod();
    this.$watch(
      () => {
        return this.entitys.length;
      },
      () => {
        this.watchMethod();
      }
    );
  },
  mounted() {
    if (this.emitParentFun) {
      this.$emit("emit:tableComponentMounted");
    }
    this.time1 = new Date().getTime();
  },
  methods: {
    reload() {
      this.timer = new Date().getTime()
    },
    /**
     * 排序回调
     */
    sortChange(val) {
      this.$emit("sortChange", val);
    },
    /**
     * 单选回调
     */
    radioHandleCurrentChange(val) {
      this.$emit("radioHandleCurrentChange", val);
    },
    /**
     * 单选选择
     */ rowClick(val) {
      this.radioRow = val;
    },
    /**
     * 默认勾选的选项
     */
    defaultCheck(rows) {
      rows.forEach((row) => {
        this.$refs.elTableRefs.toggleRowSelection(row, true);
      });
    },
    /**
     * 当选择项发生变化时会触发该事件
     */
    selectChange(selection, row) {
      this.$emit("update:selectChange", selection, row);
    },
    /**
     * expand打开事件
     */
    expandChange() {
      // this.$emit('expand:expandChange', expandedRows, expanded)
    },
    load(row, treeNode, resolve) {
      this.$emit("expand:expandChange", row, treeNode, resolve);
    },
    /**
     * 清空表格前面的勾选
     */
    clearTableSelect() {
      this.$refs.elTableRefs.clearSelection();
    },
    /**
     * 清空条件
     */
    elTableRefs() {
      this.entitys.map((item) => {
        item.querySelect = "";
        item.queryValue = "";
      });
      this.$emit("pagination", this.entitys);
    },

    watchMethod() {
      this.entitys.map((item) => {
        this.$watch(
          () => {
            return item.querySelect;
          },
          (value, oldValue) => {
            if (value.prop !== oldValue.prop) {
              item.queryValue = "";
            }
          }
        );
      });
    },
    /**
     * 增加条件
     */
    addEntity() {
      if (
        this.entitys.length <=
        this.columns.filter((item) => item.query !== false).length - 1
      ) {
        this.entitys.push({
          querySelect: "",
          queryValue: "",
          show: true,
        });
        this.entitys.map((item) => {
          item.show = true;
        });
      } else {
        this.$message({
          type: "info",
          message: "当前搜索条件已达上限",
          center: true,
        });
      }
    },
    /**
     * 删除条件
     */
    deleteEntity(index) {
      this.entitys.splice(index, 1);
      if (this.entitys.length === 1) this.entitys[0].show = false;
    },
    /**
     * 自适应宽度
     */
    flexColumnWidth(column) {
      const str = column.label;
      let flexWidth = 0;
      for (const char of str) {
        if ((char >= "A" && char <= "Z") || (char >= "a" && char <= "z")) {
          // 如果是英文字符，为字符分配8个单位宽度
          flexWidth += 8;
        } else if (char >= "\u4e00" && char <= "\u9fa5") {
          // 如果是中文字符，为字符分配20个单位宽度
          flexWidth += 19;
        } else {
          // 其他种类字符，为字符分配5个单位宽度
          flexWidth += 5;
        }
      }
      if (flexWidth < 70) {
        flexWidth = 70;
      }
      // if (flexWidth > 250) { flexWidth = 250 }
      [{ createTm: 150 }, { modifyTm: 150 }]
        .concat(this.widthEspecial)
        .map((item) => {
          const entity = Object.entries(item);
          if (entity[0][0] === column.prop) {
            flexWidth = entity[0][1] + "PX";
          }
        });
      return flexWidth;
    },
    /**
     * 搜索，传入的值代表点击的是搜索框，而不是翻页
     */
    searchList() {
      this.entitys.map((item) => {
        item.show = false;
      });
      this.$emit("searchList", this.entitys);
    },
    handleSelectionChange(rows) {
      if (this.flag) {
        this.$emit("update:multipleSelection", rows);
      } else {
        const la = rows[rows.length - 1];
        rows.splice(rows.length - 1, 1);
        rows.forEach((item) => {
          this.$refs.elTableRefs.toggleRowSelection(item);
        });
        this.$emit("update:multipleSelection", la);
      }
    },
    /**
     * 当用户手动勾选全选 Checkbox 时触发的事件
     */
    selectAll(rows) {
      if (!this.flag) {
        rows.forEach((item) => {
          this.$refs.elTableRefs.toggleRowSelection(item);
        });
        this.$message({
          message: "当前只能做单选操作!",
          center: true,
        });
      }
      this.$emit("update:selectAll", rows);
    },
    /**
     * 切换多条件搜索的下拉展示与关闭
     */
    toggleEntityShow() {
      this.entitys.map((item, index) => {
        if (index !== 0) {
          item.show = !item.show;
        }
      });
    },
    // 下面是pagination
    handleSizeChange(val) {
      this.entitys.rows = val;
      this.$emit("pagination", this.entitys, 1);
    },
    handleCurrentChange(val) {
      if (this.time1 - this.time2 > 150) {
        this.entitys.page = val;
        this.$emit("pagination", this.entitys, 2);
        this.time2 = this.time1;
        this.time1 = new Date().getTime();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/detailScss.scss";

.query-but {
  width: 38PX;
  height: 38PX;
  font-size: 20PX;
  border: 1PX solid #87d2ff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(23, 202, 205, 1);
  background: rgba(62, 100, 172, 0.3);
}
.searchBottom {
  position: relative;
  top: -2PX;
  height: 32PX;
  width: 72PX;
  font-size: 14PX;
  padding: 0PX 0PX;
  line-height: 32PX;
}
::v-deep {
  .el-input.el-input--suffix .el-input__inner[placeholder="请选择搜索项"] {
    border-right: none;
    position: relative;
    right: -1PX;
    font-size: 14PX;
    // margin: 0PX 0PX;
    padding: 0PX 10PX;
  }
  .el-select .el-input .el-select__caret {
    font-size: 12PX;
    padding: 0PX 1PX;
  }
  .el-input__icon {
    height: 30PX;
    line-height: 32PX;
    padding: 0PX 1PX;
  }
  .el-pagination .el-select .el-input .el-input__inner {
    border-radius: 1PX;
    height: 30PX;
  }
  .el-input__inner {
    border: 1PX solid #87d2ff;
    border-radius: 0PX;
    color: #17fff4;
    margin-top: -3PX;
  }
  .el-pager li:not(.disabled).active {
    background-color: rgba(62, 100, 172, 0.3);
    color: #17fff4;
    border: 1PX solid #87d2ff;
    border-radius: 1PX;
  }
  .el-table--striped .el-table__body tr.el-table__row--striped.current-row td,
  .el-table__body tr.current-row > td {
    background-color: #132e5d !important;
  }
  .sort-caret {
    pointer-events: none;
  }
}
::v-deep .el-select-dropdown {
  .el-scrollbar {
    .el-select-dropdown__wrap {
      .el-select-dropdown__list {
        .el-select-dropdown__item {
          color: #fff;
        }
        .selected {
          color: #17fff5 !important;
        }
        .el-select-dropdown__item:hover {
          color: #17fff5;
        }
      }
    }
  }
}
::v-deep .table_tr{
  td{
    border-bottom: 1px solid #5473bf !important;
  }
}
::v-deep .table_one{
  td:nth-child(1){
    border-bottom: 1px solid #5473bf !important;
    div{
      position: absolute;
      top: 30PX;
    }
  }
}
::v-deep .table_end{
  td:nth-child(1){
    // border-bottom: 1px solid #fff !important;
    div{
      position: absolute;
      top: 30PX;
    }
  }
}
</style>
